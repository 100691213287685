@import "../../resources/scss/mixins.scss";
@import "../../resources/scss/variables.scss";


.field{
     &__input{
          border: 1px solid $grayBorder;
          padding: 8px 18px;
          width: 100%;
     }
     &__title{
          @include text(14px, 18px, 400, $darkGray);
          margin-bottom: 4px;
     }
     &__block{
          margin-bottom: 12px;
     }
}