@import "../../resources/scss/mixins.scss";
@import "../../resources/scss/variables.scss";

.partner{
     padding: 24px 30px !important;
     border: 1px solid #dadada;
     background: #fff;
     margin-bottom: 16px;
     &__header{
          @include flex();
          margin-bottom: 12px;
     }
     &__btn{
          &-group{
               @include flex(flex-end, center);
               gap: 12px;
          }
          &-item{
               width: 32px;
               height: 32px;
               @include flex(center, center);
               background-color: $facebookHover;
               border: 1px solid $facebookHover;
               border-radius: 2px;
               transition: 0.2s ease;
               i{
                    color: #fff;
                    font-size: 18px;
               }
               &:hover{
                    cursor: pointer;
                    background: white;
                    i{
                         color: $facebookHover;
                    }
               }
               &-active{
                    border-color: $blue !important;
                    background-color: $blue !important;
                    &:hover{
                         background: white !important;
                         i{
                              color: $blue;
                         }
                    }
               }
          }
     }
}