$facebookHover: #229ED9;
$lightBlue: #e3f1f7;
$lightenBlue: #576996;
$blue: #274b71;
$btnBlue: #313c58;
$borderBlue: #5690cf;
$footerBg: #2c3650;
$yellow: #f4c944;
$dimYellow: #c7ad5b;
$darkGray: #222;
$textGray: #333;
$faviconGray: #999;
$grayBorder: #dadada;
$red: #E32636;