@import "../../resources/scss/mixins.scss";
@import "../../resources/scss/variables.scss";

#login{
     width: 100%;
     height: 100%;
     @include flex(center, center);
}

.login{
     width: 400px;
     padding: 18px 24px;
     border: 1px solid $grayBorder;
     background: white;
     &__title{
          @include text(20px, 24px, 500, $darkGray);
          text-align: center;
          margin-bottom: 24px;
     }
     &__block{
          display: flex;
          flex-direction: column;
          gap: 8px;
          margin-bottom: 16px;
          &-input{
               border: 1px solid $grayBorder;
               padding: 8px 16px;
          }
          &-submit{
               padding: 10px 0;
               background: $blue !important;
               color: white;
               transition: 0.2s ease;
               &:hover{
                    background: $btnBlue !important;
               }
          }
     }
     &__message{
          @include text(12px, 16px, 400, red);
     }
}