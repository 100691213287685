@import "../../resources/scss/mixins.scss";
@import "../../resources/scss/variables.scss";

.structure{
     &__link{
          border: 1px solid #dcdcdc;
          width: 100%;
          height: 54px;
          padding: 8px 8px 8px 16px;
          @include flex(flex-start, center);
          gap: 24px;
          &-btn{
               padding: 10px 20px;
               background: $blue;
               @include text(14px, 18px, 500, white);
          }
     }
     &__links{
          position: relative;
          margin-left: 40px;
          border-right: 1px solid #dcdcdc;
          margin-bottom: 16px;
          &-parts{
               margin-left: 40px;
               .structure__links-item{
                    &::before{
                         width: 60px;
                    }
               }
          }
          &::before{
               position: absolute;
               content: "";
               width: 1px;
               height: calc(100% - 27px);
               background: #dcdcdc;
               top: 0;
               right: calc(100% + 20px);
          }
          &-item{
               position: relative;
               border-left: 1px solid #dcdcdc;
               border-bottom: 1px solid #dcdcdc;
               width: 100%;
               height: 54px;
               padding: 8px 8px 8px 16px;
               @include flex(flex-start, center);
               gap: 24px;
               &-btn{
                    padding: 10px 20px;
                    background: $blue;
                    @include text(14px, 18px, 500, white);
               }
               &::before{
                    position: absolute;
                    content: "";
                    width: 20px;
                    height: 1px;
                    background: #dcdcdc;
                    top: 50%;
                    transform: translateY(-50%);
                    right: 100%;
               }
          }
     }
}