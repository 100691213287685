@import "./resources/scss/mixins.scss";
@import "./resources/scss/variables.scss";
@import "./reset.css";
@import url('https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');

body{
     font-family: 'Montserrat', sans-serif;
}

.main{
     &__wrapper{
          display: grid;
          grid-template-columns: 240px 1fr;
          height: 100vh;
     }
     &__sidebar{
          height: 100%;
          background: $blue;
          box-shadow: 4px 4px 8px 0px rgba(11, 20, 26, 0.2);
     }
     &__content{
          overflow: auto;
          height: 100%;
          background: $lightBlue;
          padding: 24px 36px;
     }
}


.section{
     &__title{
          @include text(20px, 24px, 500, $darkGray);
          margin-bottom: 16px;
     }
}


.field{
     &__block-submit{
          padding-top: 16px;
     }
     &__input{
          border: 1px solid $grayBorder;
          padding: 8px 18px;
          width: 100%;
     }
     &__title{
          @include text(14px, 18px, 400, $darkGray);
          margin-bottom: 4px;
     }
     &__submit{
          padding: 12px 24px;
          background: $blue !important;
          @include text(14px, 18px, 400, white);
          transition: 0.2s ease;
          &:hover{
               background: $lightenBlue !important;
          }
     }
}

.bg{
     padding: 24px 30px !important;
     border: 1px solid $grayBorder;
     background: #fff;
     margin-bottom: 16px;
     &__wrapper{
          @include flex(space-between, flex-start);
          gap: 40px;
     }
     &__part{
          &:first-child{
               width: 30%;
          } 
          &:last-child{
               width: 70%;
          }
          a{
               @include text(18px, 24px, 500, $blue);
               transition: 0.3s ease;
               &:hover{
                    color: $borderBlue !important;
               }
          }
     }
     &__preview{
          background-position: 0px 0px, 50% 50%;
          background-size: auto, cover;
          background-repeat: repeat, no-repeat;
          @include flex(center, center);
          height: 240px;
          &-text{
               @include text(18px, 24px, 400, white);
               text-transform: uppercase;
          }
     }
}

.file__upload{
     padding: 12px 0;
     @include flex(center, center);
     gap: 24px;
     background: $blue;
     transition: 0.3s ease;
     i{
          color:#fff;
          font-size: 36px;
     }
     h6{
          @include text(18px, 24px, 400, white);
     }
     &:hover{
          background: $lightenBlue;
     }
}

#bg{
     display: none;
}