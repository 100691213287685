@import "../../resources/scss/mixins.scss";
@import "../../resources/scss/variables.scss";

.partner{
     &__grid{
          display: grid;
          grid-template-columns: repeat(3, 1fr);
          grid-gap: 40px;
     }
     &__item{
          padding: 16px;
          border: 1px solid #dcdcdc;
          &-img{
               position: relative;
               aspect-ratio: 4/3;
               margin-bottom: 16px;
               img{
                    width: 100%;
                    height: 100%;
                    object-fit: cover;
               }
          }
     }
     &__submit{
          right: 36px !important;
          width: 160px !important; 
     }
     &__delete{
          top: 0;
          right: 0;
          position: absolute;
          background: $red !important;
          color: white;
          width: 28px;
          height: 28px;
          border-radius: 4px;
          @include flex(center, center);
          transition: 0.2s ease;
          &:hover{
               cursor: pointer;
               background: red !important;
          }
     }
}