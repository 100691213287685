@import "../../resources/scss/mixins.scss";
@import "../../resources/scss/variables.scss";

.partner{
     &__list{
          border: 1px solid #ececec;
          &-item{
               padding: 12px 24px;
               @include flex();
               &:not(:last-child){
                    border-bottom: 1px solid #ececec;
               }
               &-part{
                    &:last-child{
                         @include flex(flex-end, center);
                    }
               }
          }
     }
}