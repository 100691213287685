@import "../../resources/scss/mixins.scss";
@import "../../resources/scss/variables.scss";

.main{
     &__link{
          display: block;
          position: relative;
          border-bottom: 1px solid $btnBlue;
          padding: 12px 20px;
          @include text(14px, 18px, 500, white);
          transition: 0.3s ease;
          &-border{
               position: absolute;
               width: 6px;
               height: 100%;
               left: 0;
               top: 0;
               background: $blue;
               transition: 0.2s ease;
          }
          &:hover{
               background: $btnBlue;
               padding: 12px 20px 12px 26px;
               .main__link-border{
                    background: $yellow;
               }
          }
     }
}