@import "../../resources/scss/mixins.scss";
@import "../../resources/scss/variables.scss";

.chairman__item{
     margin-top: 16px;
     padding-bottom: 16px;
     border-bottom: 1px solid $grayBorder;
     &-message{
          @include text(18px, 24px, 400, $textGray);
     }
     &-header{
          @include flex(space-between, center);
          &-part{
               &:first-child {
                    @include flex(flex-start, center);
                    gap: 40px;   
               }
               &:last-child{
                    @include flex(flex-end, center);
                    gap: 16px;
               }
               form{
                    display: flex;
                    justify-content: flex-end;
                    gap: 8px;
               }
          }
          &-item{
               @include flex(flex-start, center);
               gap: 12px;
               i{
                    font-size: 20px;
                    color: $textGray;
               }
               p{
                    @include text(16px, 20px, 400, $textGray);
               }
          }
     }
     &-title{
          @include text(20px, 24px, 600, $textGray);
          margin: 20px 0 10px;
     }
     &-file{
          @include text(18px, 24px, 500, $blue);
          list-style-position: inside;
          a {
               color: $blue !important;
               transition: 0.25s ease;
               &:hover{
                    color: $borderBlue !important;
               }
          }
     }
     &-textarea{
          width: 100%;
          padding: 8px 16px;
          border: 1px solid $grayBorder;
          resize: none;
     }
     &-edit{
          @include flex(flex-start, center);
          gap: 20px;
          margin: 20px 0 10px;
          button{
               width: 32px;
               height: 32px;
               background: $blue !important;
               color: white;
               border-radius: 4px;
               border: 0;
               transition: 0.3s ease;
               &:hover{
                    background: $btnBlue !important;
               }
          }
          h2 {
               margin: 0;
          }
     }
     &-delete{
          background: $red !important;
          color: white;
          width: 28px;
          height: 28px;
          border-radius: 4px;
          @include flex(center, center);
          transition: 0.2s ease;
          &:hover{
               cursor: pointer;
               background: red !important;
          }
     }
     &-save{
          width: 28px;
          height: 28px;
          background: $blue !important;
          color: white;
          border-radius: 4px;
          transition: 0.2s ease;
          &:hover{
               background: $btnBlue !important;
          }
     }
     &-status{
          @include flex(flex-start, center);
          gap: 6px;
          margin-right: 8px;
     }
}