@keyframes spinner {
0% {
     transform: rotate(0deg);
}
100% {
     transform: rotate(360deg);
}
}
.spinner-container{
     display: flex;
     justify-content: center;
     align-items: center;
}
.loading-spinner {
     width: 150px;
     height: 150px;
     border: 20px solid #f3f3f3; /* Light grey */
     border-top: 20px solid #576996; /* Black */
     border-radius: 50%;
     animation: spinner 1.5s linear infinite;
}